enum ConditionOperator {
  AND = 'AND',
  OR = 'OR',
  EQ = 'EQ',
  NE = 'NE',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  IN = 'IN',
  NIN = 'NIN',
  SIZE = 'SIZE',
  EXISTS = 'EXISTS',
  REGEX = 'REGEX',
  DAYS_SINCE_GT = 'DAYS_SINCE_GT',
  DAYS_SINCE_LT = 'DAYS_SINCE_LT',
  MINUTES_SINCE_GT = 'MINUTES_SINCE_GT',
  MINUTES_SINCE_LT = 'MINUTES_SINCE_LT'
}

export default ConditionOperator
