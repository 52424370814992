import { createApp, h } from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import { Auth0 } from '@/auth'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createI18n } from 'vue-i18n'
import { messages, locale, fallbackLocale, globalInjection, legacy } from '@/assets/languages'
import '@/assets/style/index.css'

async function init() {
  const AuthPlugin = await Auth0.init({
    onRedirectCallback: (appState) => {
      router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
    },
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID || '',
    domain: process.env.VUE_APP_AUTH0_DOMAIN || '',
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    redirectUri: `${window.location.origin}/callback`,
    databaseConnection: process.env.VUE_APP_AUTH0_DATABASE_CONNECTION || '',
    scope: process.env.VUE_APP_AUTH0_SCOPE
  })

  const i18n = createI18n({
    legacy,
    locale,
    fallbackLocale,
    globalInjection,
    messages
  })

  createApp({
    render: () => h(App)
  })
    .use(AuthPlugin)
    .use(router)
    .use(vuetify)
    .use(i18n)
    .use(VueAxios, axios)
    .mount('#app')
}

init()
