import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Automations from '../views/Automations.vue'
import Editor from '../views/Editor.vue'
import Login from '../views/Login.vue'
import { Auth0 } from '@/auth'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: Auth0.routeGuard
  },
  {
    path: '/callback',
    redirect: '/automations',
    beforeEnter: Auth0.routeGuard
  },
  {
    path: '/automations',
    name: 'Automations',
    component: Automations,
    beforeEnter: Auth0.routeGuard
  },
  {
    path: '/automations/:id',
    name: 'Editor',
    component: Editor,
    beforeEnter: Auth0.routeGuard
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
