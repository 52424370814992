import axios, { AxiosInstance } from 'axios'
import { getInstance } from '../auth'

const createHoppermationAPIClient = async (): Promise<AxiosInstance> => {
  const authPlugin = getInstance()
  const token = await authPlugin.getTokenSilently()

  return axios.create({
    baseURL: process.env.VUE_APP_HOPPERMATION,
    headers: { Authorization: `Bearer ${token}` }
  })
}

export default createHoppermationAPIClient
