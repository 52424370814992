import de from './de.json'

export const legacy = false

export const locale = 'de'

export const globalInjection = true

export const fallbackLocale = 'de'

export const messages = {
  de: de
}
