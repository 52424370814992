export enum StepType {
  TRIGGER = 'TRIGGER',
  GOAL = 'GOAL',
  WAIT = 'WAIT',
  EMAIL = 'EMAIL',
  SUBSCRIBE = 'SUBSCRIBE',
  CONDITION = 'CONDITION'
}

export default StepType
