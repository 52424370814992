/* eslint-disable @typescript-eslint/no-explicit-any */
import createAuth0Client, {
  Auth0Client,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  RedirectLoginOptions,
  User,
  IdToken
} from '@auth0/auth0-spa-js'
import { App, Plugin, computed, reactive, watchEffect } from 'vue'
import { NavigationGuardWithThis } from 'vue-router'

let client: Auth0Client

export const getInstance = (): Auth0Client => client
export const getAuth0State = (): Auth0PluginState => state

interface Auth0PluginState {
  loading: boolean
  isAuthenticated: boolean
  user: User | undefined
  accessToken: string
  popupOpen: boolean
  error: any
}

const state = reactive<Auth0PluginState>({
  loading: true,
  isAuthenticated: false,
  user: {},
  accessToken: '',
  popupOpen: false,
  error: null
})

async function handleRedirectCallback(): Promise<void> {
  state.loading = true

  try {
    await client.handleRedirectCallback()
    state.user = await client.getUser()
    state.accessToken = await client.getTokenSilently()
    state.isAuthenticated = true
  } catch (e) {
    state.error = e
  } finally {
    state.loading = false
  }
}

function loginWithRedirect(o: RedirectLoginOptions): Promise<void> {
  return client.loginWithRedirect(o)
}

function getIdTokenClaims(o: GetIdTokenClaimsOptions): Promise<IdToken> {
  return client.getIdTokenClaims(o)
}

function getTokenSilently(o: GetTokenSilentlyOptions): Promise<void> {
  return client.getTokenSilently(o)
}

function getTokenWithPopup(o: GetTokenWithPopupOptions): Promise<string> {
  return client.getTokenWithPopup(o)
}

function logout(o: LogoutOptions): Promise<void> | void {
  return client.logout(o)
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  accessToken: computed(() => state.accessToken),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  logout
}

const routeGuard: NavigationGuardWithThis<undefined> = (to: any, from: any, next: any): Promise<any> | any => {
  const { isAuthenticated, loading, loginWithRedirect } = authPlugin

  const verify = async () => {
    // If the user is authenticated, continue with the route
    if (isAuthenticated.value) {
      if (to.name === 'Login') {
        return next({ name: 'Automations' })
      }
      return next()
    }

    if (to.name === 'Login') {
      return next()
    }

    // Otherwise, log in
    await loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    state.error = null
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify()
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (!loading.value) {
      return verify()
    }
  })
}

interface Auth0PluginOptions {
  domain: string
  clientId: string
  audience?: string
  redirectUri?: string
  databaseConnection: string
  scope?: string
  onRedirectCallback(appState: any): void
}

async function init(options: Auth0PluginOptions): Promise<Plugin> {
  client = await createAuth0Client({
    domain: options.domain,
    client_id: options.clientId,
    audience: options.audience,
    redirect_uri: options.redirectUri,
    databaseConnection: options.databaseConnection,
    scope: options.scope
  })

  try {
    // If the user is returning to the app after authentication
    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback()

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      options.onRedirectCallback(appState)
    }
  } catch (e) {
    state.error = e
  } finally {
    // Initialize our internal authentication state
    state.isAuthenticated = await client.isAuthenticated()
    state.user = await client.getUser()
    state.accessToken = state.isAuthenticated ? await client.getTokenSilently() : ''
    state.loading = false
  }

  return {
    install: (app: App) => {
      app.provide('Auth', authPlugin)
    }
  }
}

export const Auth0 = {
  init,
  routeGuard
}
