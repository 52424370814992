import { IStepData, ITriggerEvent } from './../../interfaces/hoppermation'
import TimeUnit from '../enums/TimeUnit'
import StepType from '@/lib/enums/StepType'
import { IStep } from '@/interfaces/hoppermation'
import { DfNode, DfConnections, DfExportStructure, DfNodeData } from '@/interfaces/drawflow'
import { getEventLabel } from '../helper'

export const prepareDataForImport = (data: IStep[], triggerEvents: ITriggerEvent[]): DfExportStructure => {
  const preparedSteps = {} as { [key: string]: DfNode }

  const getOutput = (id: string, inuptId: string) => {
    const step = data.find(({ uiId }) => uiId === inuptId)
    if (step?.type === StepType.CONDITION) {
      if (step.nextUiStepIds[0] === id) {
        return 'output_2'
      }
      if (step.nextUiStepIds[1] === id) {
        return 'output_3'
      }
    }
    return 'output_1'
  }

  data.forEach((step: IStep) => {
    // get all input connections
    const preparedInputs: { input_1?: { connections: DfConnections[] } } = {}
    if (![StepType.TRIGGER, StepType.GOAL].includes(step.type)) {
      const inputsConnections: DfConnections[] = []
      for (const inputId of step.prevUiStepIds) {
        inputsConnections.push({
          node: inputId,
          input: getOutput(step.uiId, inputId)
        })
      }
      preparedInputs['input_1'] = {
        connections: inputsConnections
      }
    }

    // get all output connections
    const preparedOutputs: {
      output_1?: { connections: DfConnections[] }
      output_2?: { connections: DfConnections[] }
      output_3?: { connections: DfConnections[] }
    } = {}
    const outputsConnections: DfConnections[] = []
    if (step.type === StepType.CONDITION) {
      preparedOutputs['output_1'] = { connections: [] }

      preparedOutputs['output_2'] = {
        connections: step.nextUiStepIds[0]
          ? [
              {
                node: step.nextUiStepIds[0],
                input: 'input_1'
              }
            ]
          : []
      }

      preparedOutputs['output_3'] = {
        connections: step.nextUiStepIds[1]
          ? [
              {
                node: step.nextUiStepIds[1],
                input: 'input_1'
              }
            ]
          : []
      }
    } else if (step.type !== StepType.GOAL) {
      for (const outputId of step.nextUiStepIds) {
        outputsConnections.push({
          node: outputId,
          input: 'input_1'
        })
      }
      preparedOutputs['output_1'] = {
        connections: outputsConnections
      }
    }

    // get node data
    const mappedStepData = mapDataForImport(step, (event) => getEventLabel(triggerEvents, event))

    // build the drawflow step structure
    const stepStructure: DfNode = {
      id: parseInt(step.uiId),
      name: step.type,
      data: mappedStepData,
      class: step.type,
      html: step.type,
      typenode: 'vue',
      inputs: preparedInputs,
      outputs: preparedOutputs,
      pos_x: step.position.x,
      pos_y: step.position.y
    }
    preparedSteps[step.uiId] = stepStructure
  })

  const drawflowStructure = {
    drawflow: {
      Home: {
        data: preparedSteps
      }
    }
  }

  return drawflowStructure
}

const mapDataForImport = (step: IStep, getEventLabel: (name?: string) => string): DfNodeData => {
  switch (step.type) {
    case StepType.TRIGGER:
      return {
        stepName: step.name,
        eventName: step.eventName,
        eventLabel: getEventLabel(step.eventName)
      }
    case StepType.GOAL:
      return {
        stepName: step.name,
        eventName: step.eventName,
        eventLabel: getEventLabel(step.eventName),
        condition: step.condition
      }
    case StepType.WAIT:
      return {
        stepName: step.name,
        eventName: step.eventName,
        eventLabel: getEventLabel(step.eventName),
        duration: {
          unit: step.duration?.unit || TimeUnit.MINUTE,
          value: step.duration?.value || 0
        }
      }
    case StepType.EMAIL:
      return {
        stepName: step.name,
        templateAlias: step.templateAlias,
        renderEngine: step.renderEngine,
        enrichment: step.enrichment ? step.enrichment : []
      }
    case StepType.SUBSCRIBE:
      return {
        stepName: step.name,
        templateAlias: step.templateAlias,
        renderEngine: step.renderEngine,
        alreadySubscribedTemplateAlias: step.alreadySubscribedTemplateAlias,
        enrichment: step.enrichment ? step.enrichment : []
      }
    case StepType.CONDITION:
      return {
        stepName: step.name,
        condition: step.condition
      }
    default:
      return {
        stepName: step.name
      }
  }
}

export const prepareDataForExport = (data: { [key: string]: DfNode }): IStep[] => {
  const preparedSteps: IStep[] = []

  Object.values(data).forEach((step: DfNode) => {
    const { stepName, ...stepData } = step.data
    const preparedData = mapDataForExport(step.name, stepData)
    const preparedStep = {
      name: stepName,
      type: step.name,
      uiId: step.id.toString(),
      nextUiStepIds: getOutputs(step),
      prevUiStepIds: getConnectionStepIds(step.inputs?.input_1?.connections),
      position: {
        x: step.pos_x,
        y: step.pos_y
      },
      ...preparedData
    } as IStep
    preparedSteps.push(preparedStep)
  })

  return preparedSteps
}

const getOutputs = (step: DfNode): (null | string)[] | [] => {
  if (step.class === StepType.CONDITION) {
    const falseBranch = step.outputs?.output_2?.connections[0]?.node
    const trueBranch = step.outputs?.output_3?.connections[0]?.node
    return [falseBranch || null, ...(trueBranch ? [trueBranch] : [])]
  }
  return getConnectionStepIds(step.outputs?.output_1?.connections)
}

const getConnectionStepIds = (connections: DfConnections[] | undefined): string[] | [] => {
  return !connections ? [] : connections.map((connection) => connection.node)
}

const mapDataForExport = (stepName: string, stepData: DfNodeData): IStepData => {
  switch (stepName) {
    case StepType.TRIGGER:
      return {
        eventName: stepData.eventName
      }
    case StepType.GOAL:
      return {
        eventName: stepData.eventName,
        condition: stepData.condition
      }
    case StepType.WAIT:
      return {
        ...(stepData.eventName && { eventName: stepData.eventName }),
        ...(stepData.duration?.value &&
          stepData.duration.value > 0 && {
            duration: {
              unit: stepData.duration?.unit,
              value: stepData.duration?.value
            }
          })
      }
    case StepType.EMAIL:
      return {
        templateAlias: stepData.templateAlias,
        renderEngine: stepData.renderEngine,
        enrichment: stepData.enrichment ? stepData.enrichment : []
      }
    case StepType.SUBSCRIBE:
      return {
        templateAlias: stepData.templateAlias,
        renderEngine: stepData.renderEngine,
        alreadySubscribedTemplateAlias: stepData.alreadySubscribedTemplateAlias,
        enrichment: stepData.enrichment ? stepData.enrichment : []
      }
    case StepType.CONDITION:
      return {
        condition: stepData.condition
      }
    default:
      return {}
  }
}
