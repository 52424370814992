import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/lib/styles/main.sass'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'

const colors = {
  background: '#FFFFFF',
  surface: '#FFFFFF',
  primary: '#2598FD',
  'primary-darken-1': '#3700B3',
  secondary: '#E45E9F',
  'secondary-darken-1': '#018786',
  error: '#ff5454',
  info: '#2196F3',
  success: '#3BAC54',
  warning: '#FB8C00',
  active: '#63C175',
  disabled: '#CECECE',
  disabledText: '#737373',
  subtle: '#CCCCCC',
  subtitle: '#888888'
}
const customTheme = {
  dark: false,
  colors,
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.6,
    'disabled-opacity': 0.38,
    'activated-opacity': 0.12,
    'idle-opacity': 0.04,
    'hover-opacity': 0.12,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'dragged-opacity': 0.08,
    'pressed-opacity': 0.16,
    'kbd-background-color': '#212529',
    'kbd-color': '#FFFFFF',
    'code-background-color': '#C2C2C2'
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'customTheme',
    options: { customProperties: true },
    themes: {
      light: {
        colors
      },
      dark: {
        colors
      },
      customTheme
    }
  },
  components,
  directives
})
