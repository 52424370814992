import TriggerStep from './StepTypes/Trigger.vue'
import GoalStep from './StepTypes/Goal.vue'
import WaitStep from './StepTypes/Wait.vue'
import EmailStep from './StepTypes/Email.vue'
import SubscribeStep from './StepTypes/Subscribe.vue'
import ConditionStep from './StepTypes/Condition.vue'

import ConditionIcon from '@/assets/images/condition.png'
import SubscribeIcon from '@/assets/images/subscribe.png'
import TriggerIcon from '@/assets/images/trigger.png'
import GoalIcon from '@/assets/images/goal.png'
import WaitIcon from '@/assets/images/wait.png'
import EmailIcon from '@/assets/images/email.png'
import StepType from '@/lib/enums/StepType'
import TimeUnit from '@/lib/enums/TimeUnit'
import { IConditionGroup, IEnrichment } from '@/interfaces/hoppermation'
import ConditionOperator from '@/lib/enums/ConditionOperator'
import ConditionScope from '@/lib/enums/ConditionScope'
import NumberOperator from '@/lib/enums/NumberOperator'

type TStepTemplate<T> = {
  type: StepType
  inputs: number
  outputs: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any
  title: string
  icon: string
  data: T
}
interface IStepTemplates {
  [StepType.TRIGGER]: TStepTemplate<{ stepName: string; eventName: string; eventLabel: string }>
  [StepType.GOAL]: TStepTemplate<{
    stepName: string
    eventName: string
    eventLabel: string
    condition: IConditionGroup
  }>
  [StepType.WAIT]: TStepTemplate<{
    stepName: string
    eventName: string
    eventLabel: string
    duration: {
      unit: TimeUnit
      value: number
    }
  }>
  [StepType.EMAIL]: TStepTemplate<{
    stepName: string
    templateAlias: string
    renderEngine: string
    enrichment: IEnrichment[]
  }>
  [StepType.SUBSCRIBE]: TStepTemplate<{
    stepName: string
    templateAlias: string
    renderEngine: string
    alreadySubscribedTemplateAlias: string
    enrichment: IEnrichment[]
  }>
  [StepType.CONDITION]: TStepTemplate<{
    stepName: string
    condition: IConditionGroup
  }>
}

const stepTemplates: IStepTemplates = {
  [StepType.TRIGGER]: {
    type: StepType.TRIGGER,
    inputs: 0,
    outputs: 1,
    component: TriggerStep,
    title: 'steps.trigger.title',
    icon: TriggerIcon,
    data: {
      stepName: '',
      eventLabel: '',
      eventName: ''
    }
  },
  [StepType.GOAL]: {
    type: StepType.GOAL,
    inputs: 0,
    outputs: 0,
    component: GoalStep,
    title: 'steps.goal.title',
    icon: GoalIcon,
    data: {
      stepName: '',
      eventLabel: '',
      eventName: '',
      condition: {
        operator: ConditionOperator.AND,
        scope: ConditionScope.CONTACT,
        count: 1,
        countOperator: NumberOperator.GTE,
        conditions: [],
        conditionGroups: []
      }
    }
  },
  [StepType.WAIT]: {
    type: StepType.WAIT,
    inputs: 1,
    outputs: 1,
    component: WaitStep,
    title: 'steps.wait.title',
    icon: WaitIcon,
    data: {
      stepName: '',
      eventName: '',
      eventLabel: '',
      duration: {
        unit: TimeUnit.MINUTE,
        value: 0
      }
    }
  },
  [StepType.EMAIL]: {
    type: StepType.EMAIL,
    inputs: 1,
    outputs: 1,
    component: EmailStep,
    title: 'steps.email.title',
    icon: EmailIcon,
    data: {
      stepName: '',
      templateAlias: '',
      renderEngine: 'HOPPERMATION',
      enrichment: []
    }
  },
  [StepType.SUBSCRIBE]: {
    type: StepType.SUBSCRIBE,
    inputs: 1,
    outputs: 1,
    component: SubscribeStep,
    title: 'steps.subscribe.title',
    icon: SubscribeIcon,
    data: {
      stepName: '',
      templateAlias: '',
      renderEngine: 'HOPPERMATION',
      alreadySubscribedTemplateAlias: '',
      enrichment: []
    }
  },
  [StepType.CONDITION]: {
    type: StepType.CONDITION,
    inputs: 1,
    outputs: 3,
    component: ConditionStep,
    title: 'steps.condition.title',
    icon: ConditionIcon,
    data: {
      stepName: '',
      condition: {
        operator: ConditionOperator.AND,
        scope: ConditionScope.CONTACT,
        count: 1,
        countOperator: NumberOperator.GTE,
        conditions: [],
        conditionGroups: []
      }
    }
  }
}

export default stepTemplates
