import { ITriggerEvent } from '@/interfaces/hoppermation'
import { TimeUnit } from '@/lib/enums/TimeUnit'
import ConditionOperator from './enums/ConditionOperator'
import ConditionScope from './enums/ConditionScope'
import NumberOperator from './enums/NumberOperator'

export const readableTime = (isoTime: string): string => {
  const readable = new Date(isoTime)
  const d = ('0' + readable.getDate()).slice(-2)
  const m = ('0' + (readable.getMonth() + 1)).slice(-2)
  const y = readable.getFullYear()

  const hour = ('0' + readable.getHours()).slice(-2)
  const minute = ('0' + readable.getMinutes()).slice(-2)

  return `${d}.${m}.${y} - ${hour}:${minute}`
}

export const readableTimeUnits = (unit: TimeUnit): string => {
  return {
    [TimeUnit.MINUTE]: 'details.wait.readableTimeUnits.minute',
    [TimeUnit.HOUR]: 'details.wait.readableTimeUnits.hour',
    [TimeUnit.DAY]: 'details.wait.readableTimeUnits.day',
    [TimeUnit.WEEK]: 'details.wait.readableTimeUnits.week',
    [TimeUnit.MONTH]: 'details.wait.readableTimeUnits.month'
  }[unit]
}

export const readableConditionOperators = (operator: ConditionOperator): string => {
  return {
    [ConditionOperator.AND]: 'dialog.condition.operator.values.AND',
    [ConditionOperator.OR]: 'dialog.condition.operator.values.OR',
    [ConditionOperator.EQ]: 'dialog.condition.operator.values.EQ',
    [ConditionOperator.NE]: 'dialog.condition.operator.values.NE',
    [ConditionOperator.GT]: 'dialog.condition.operator.values.GT',
    [ConditionOperator.GTE]: 'dialog.condition.operator.values.GTE',
    [ConditionOperator.LT]: 'dialog.condition.operator.values.LT',
    [ConditionOperator.LTE]: 'dialog.condition.operator.values.LTE',
    [ConditionOperator.IN]: 'dialog.condition.operator.values.IN',
    [ConditionOperator.NIN]: 'dialog.condition.operator.values.NIN',
    [ConditionOperator.SIZE]: 'dialog.condition.operator.values.SIZE',
    [ConditionOperator.EXISTS]: 'dialog.condition.operator.values.EXISTS',
    [ConditionOperator.REGEX]: 'dialog.condition.operator.values.REGEX',
    [ConditionOperator.DAYS_SINCE_GT]: 'dialog.condition.operator.values.DAYS_SINCE_GT',
    [ConditionOperator.DAYS_SINCE_LT]: 'dialog.condition.operator.values.DAYS_SINCE_LT',
    [ConditionOperator.MINUTES_SINCE_GT]: 'dialog.condition.operator.values.MINUTES_SINCE_GT',
    [ConditionOperator.MINUTES_SINCE_LT]: 'dialog.condition.operator.values.MINUTES_SINCE_LT'
  }[operator]
}

export const readableNumberOperators = (operator: NumberOperator): string => {
  return {
    [NumberOperator.EQ]: 'dialog.condition.operator.values.EQ',
    [NumberOperator.NE]: 'dialog.condition.operator.values.NE',
    [NumberOperator.GT]: 'dialog.condition.operator.values.GT',
    [NumberOperator.GTE]: 'dialog.condition.operator.values.GTE',
    [NumberOperator.LT]: 'dialog.condition.operator.values.LT',
    [NumberOperator.LTE]: 'dialog.condition.operator.values.LTE'
  }[operator]
}

export const readableConditionScopes = (operator: ConditionScope): string => {
  return {
    [ConditionScope.CONTACT]: 'dialog.condition.scope.values.CONTACT',
    [ConditionScope.EVENTS]: 'dialog.condition.scope.values.EVENTS',
    [ConditionScope.AUTOMATION_EVENTS]: 'dialog.condition.scope.values.AUTOMATION_EVENTS',
    [ConditionScope.AUTOMATION_CONTEXT]: 'dialog.condition.scope.values.AUTOMATION_CONTEXT'
  }[operator]
}

export const getEventLabel = (triggerEvents: ITriggerEvent[], eventName?: string): string => {
  return eventName ? triggerEvents.find(({ event }) => event === eventName)?.eventLabel || eventName : ''
}
